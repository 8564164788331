<template>
  <section class="home-user">
    <!-- <div class="user-name">
      {{username}}
    </div>
    <div class="separator" /> -->
    <div class="user-organistaion">
      {{username}}
    </div>
    <!-- <user /> -->
    <div class="settings ml-3 logout" >
      <b-button size="sm" variant="outline-brand" @click="logout" >
        Sair
        <!-- <log-out-icon size="1x" /> -->
      </b-button>
    </div>
    <!-- <div class="settings ml-3">
      <log-out-icon size="1x" />
    </div> -->
  </section>
</template>

<script>
import { LogOutIcon } from 'vue-feather-icons'
import User from './User.vue'
import firebase from "firebase";

// this.$session.set('email', this.form.email)

export default {
  components: {
    User,
    LogOutIcon
  },
  data() {
    return {
      username : ""
    };
  },
  mounted() {
    this.username = this.$session.get('email')
  },
  methods: {
    logout: function () {
      this.$analytics.logEvent("logout", {
        method: "Google",
        event_category: "logout",
      });
      this.$session.destroy()
      this.$router.push('/')
      firebase
        .auth()
        .signOut();

    }
  },
}
</script>

<style lang="scss" scoped>
.home-user {
  align-items: center;
  display: flex;
  position: relative;
  color: $gray-800;
  justify-content: flex-end;
  padding: 24px;
  margin-bottom: 80px;
}

.user-name {
  font-weight: bold;
}

.separator {
  height: 24px;
  width: 1px;
  background-color: $gray-800;
  margin: 0 10px;
}

.settings {
  height: 20px;

  > svg {
    width: 20px;
    height: 20px;
  }
}

</style>

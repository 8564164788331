<template>
  <div class="home-template">
    <home-user />
    <div class="home-logo">
      <img
        src="../assets/logo.svg"
        alt=""
      >
    </div>
    <router-view />
  </div>
</template>

<script>
import HomeUser from '@/components/HomeUser.vue'

export default {
  components: {
    HomeUser
  }
}
</script>

<style lang="scss" scoped>
.home-template {
  background: url('../assets/map-background.png'), center center no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  min-height: 100vh;
  height: auto;  
}
.home-template::before {
  content:'';
    height: 100%;
    position:absolute;
    top: -2px;
    bottom:0;
    left:0;
    right:0;
    background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255, 255, 255, 0) 30%, #ffffff 94%);
    z-index: 0;
}

.home-logo {
  width: 300px;
  margin: 0 auto 120px;
  position: relative;
  > img {
    width: 100%;
  }
}

</style>

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapVue } from 'bootstrap-vue'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import { firebase } from '@firebase/app';
import 'firebase/analytics';
import VueSession from 'vue-session'
import axios from 'axios'
import VueMask from 'v-mask'


const firebaseConfig = {
    apiKey: process.env.VUE_APP_API_KEY,
    authDomain: process.env.VUE_APP_KEY_AUTH_DOMAIN,
    databaseURL: process.env.VUE_APP_KEY_DATABASE_URL,
    projectId: process.env.VUE_APP_PROJECT_ID,
    storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_ID,
    measurementId: process.env.VUE_APP_MEASUREMENT_ID
}


// Initialize Firebase
firebase.initializeApp(firebaseConfig);
Vue.prototype.$analytics = firebase.analytics();

Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(VueLodash, { lodash: lodash })
Vue.use(VueSession)
Vue.use(VueMask)


axios.interceptors.request.use(async config => {
    //config.headers.token = Vue.prototype.$session.get("token")
    if (firebase.auth().currentUser) {
        config.headers.token = await firebase.auth().currentUser.getIdToken()
    } else {
        this.$router.push("/");
    }
    return config
}, (error) => {
    console.log(error)
    return Promise.reject(error)
})

document.title = "Dataland"

// firebase.auth().signOut()

// firebase.auth().onAuthStateChanged(function(user) {
//     new Vue({
//         router,
//         store,
//         render: h => h(App)
//     }).$mount('#app')
// })

let app;

firebase.auth().onAuthStateChanged(async user => {
    if (!app) {
        //wait to get user
        var user = await firebase.auth().currentUser;
        //start app
        app = new Vue({
            router,
            created() {
                //redirect if user not logged in
                if (!user) {
                    this.$router.push("/");
                }
            },
            render: h => h(App)
        }).$mount("#app");
    }
});